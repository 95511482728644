@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.homeContact-wrapper{
  padding: 60px 0 104px 0;
  .homeContact-img{
    position: relative;
    padding-top: 30%;
    img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .homeContact-bottom{
    padding: 0 $mainPadding;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .homeContact-info{
      width: 586px;
      .homeContact-title{
        color: #231F20;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.72px;
      }
      .homeContact-description{
        color: #696773;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        margin-top: 20px;
      }
    }
    .homeContact-number{
      width: 198px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color:$brandColor;
      border-radius: 4px;
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      svg{
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
    }
  }

  @media screen and (max-width: 1050px){
    padding: 60px 0 0 0;
    .homeContact-bottom{
      flex-direction: column;
      .homeContact-info{
        width: 100%;
      }
      .homeContact-number{
        width: 100%;
        margin-top: 40px;
      }
    }
  }
}