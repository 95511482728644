@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";


.header-wrapper {
  -webkit-user-select:none!important;
  user-select: none!important;
  border-bottom: 1px solid #EAECF0;
  padding: 0 $mainPadding;
  position: relative;
  top: 0;
  z-index: 1002;
  height: 138px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .link-menu{
    display: flex;
    align-items: center;
    .hybrid-logo{
      margin-right: 82px;
    }
    .active{
      .nav-item{
        color:$brandColor;
      }
    }
      a{
        text-decoration: none !important;
        .nav-item{
          list-style-type: none;
          text-decoration: none !important;
          color: #231F20;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          margin-right: 52px;
        }
      }
  }
  .nav-menu{
    display: flex;
    align-items: center;
    .contact-block{
      list-style-type: none;
      text-decoration: none;
      width: 134px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color:$brandColor;
      border-radius: 4px;
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    .nav-item{
      list-style-type: none;
      text-decoration: none;
      margin-left: 30px;
    }
    .header_burger{
      display: none;
    }
  }

  @media screen and (max-width: 1050px){
    height: 66px;
    overflow: hidden;
    padding: 0 0 0 20px;
    width: 100%;
    background-color: white;
    &.responsive{
      position: fixed;
    }
    .link-menu{
      .hybrid-logo{
        width: 74px;
        height: 34px;
        svg{
          width: 100%;
          height: 100%;
        }
      }
      a{
        .nav-item{
          display: none;
        }
      }
    }
    .nav-menu{
      .contact-block{
        display: none;
      }
      .nav-item{
        display: none;
      }
      .header_burger{
        display: flex;
      }
    }
  }
}