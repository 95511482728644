@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.mainBlock-wrapper{
  width: 100%;
  height: 650px;
  overflow: hidden;
  position: relative;
  .img-wrapper{
    position: relative;
    padding-top: 45.2%;
    img{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
    }
  }
  .mainBlock-info-wrapper{
    display: flex;
    flex-direction: column;
    padding: 0 $mainPadding;
    position: absolute;
    top: 12%;
    .mainBlock-title{
      width: 544px;
      color: #231F20;
      font-size: 60px;
      font-style: normal;
      font-weight: 600;
      line-height: 72px;
      text-align: left;
      letter-spacing: -1.2px;
    }
    .mainBlock-description{
      width: 544px;
      color: #696773;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      text-align: left;
      margin-top: 24px;
    }
    .invalid{
      border: 1px solid red;
    }
    .main-input-wrapper{
      width: 480px;
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 6px;
      margin-top: 48px;
      border-radius: 4px;
      background: #FFF;
      box-shadow: 18px 18px 84px 20px rgba(235, 235, 235, 0.90);
      .custom-input-group{
        margin-bottom: unset;
        width:calc(100% - 134px);
        input{
          border-bottom: unset;
          //white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .sent-email{
        height: 44px;
        width: 134px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color:$brandColor;
        border-radius: 4px;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }

  @media screen and (max-width: 1050px){
    height: unset;
    .img-wrapper{
      padding-top: 126.4%;
      img{
        object-fit: contain;
      }
    }
    .mainBlock-info-wrapper{
      width: 100%;
      top: 52px;
      .mainBlock-title{
        width:100%;
        color: #231F20;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 46px;
        letter-spacing: -0.72px;
      }
      .mainBlock-description{
        color: #696773;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        width:100%;
        margin-top: 24px;
      }
      .main-input-wrapper{
        width: 100%;
      }
    }
  }
}