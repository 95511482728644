@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.homeBlock-wrapper{
  padding: 0 $mainPadding;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FDFDFF;
  .home-left-block{
    width: 48%;
    .homeBlock-title{
      color: #231F20;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 44px;
      letter-spacing: -0.72px;
    }
    .homeBlock-description{
      color: #696773;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin-top: 20px;
    }
    .homeBlock-more{
      cursor: pointer;
      margin-top: 20px;
      width: 198px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      background-color:$brandColor;
      border-radius: 4px;
    }
  }
  .home-right-block{
    width: 50%;
    .home-imag{
      position: relative;
      padding-top: 142.5%;
      img{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }
  }

  @media screen and (max-width: 1050px){
    padding: 52px $mainPadding;
    .home-left-block{
      width: 100%;
      .homeBlock-title{
        font-size: 26px;
      }
      .homeBlock-description{
        font-size: 16px;
      }
      .homeBlock-more{
        width: 100%;
      }
    }
    .home-right-block{
      display: none;
    }
  }
}