@import "../../../../assets/styles/mainVariables";
@import "../../../../assets/styles/fonts";

.productCard-wrapper{
  width: calc(100% / 3 - 26px);
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  .productCard-checkbox{
    display: flex;
    align-self: flex-end;
    .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner{
      background-color:#231F20!important;
    }
    .ant-checkbox-wrapper{
      .ant-checkbox-checked .ant-checkbox-inner::after{
        height: 12px;
        transform: rotate(45deg) scale(1) translate(-52%, -68%)!important;
      }
      .ant-checkbox-checked::after{
        border: unset!important;
      }
      .ant-checkbox{
        .ant-checkbox-inner{
          border-radius: 10px;
          width: 30px;
          height: 30px;
          border: 2px solid #231F20!important;
        }
      }
    }
  }
  .productCard-img{
    margin-top: 8px;
    position: relative;
    padding-top: 71%;
    img{
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      object-fit: cover;
    }
  }
  .productCard-info{
    margin-top: 12px;
    .productCard-title{
      color: #231F20;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
    .productCard-description{
      color: #696773;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-top: 12px;
    }
  }
}