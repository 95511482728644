@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";

.subscribe-wrapper {
  padding: 100px $mainPadding;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .subscribe-info {
    width: 48%;
    .subscribe-title {
      color: #231F20;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 44px;
      letter-spacing: -0.72px;
    }

    .subscribe-description {
      color: #696773;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin-top: 20px;
    }
  }
  .subscribe-input{
    height: 56px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    padding: 0 6px;
    margin-top: 48px;
    .custom-input-group{
      width:290px;
      margin-bottom: unset;
      border: 1px solid #D0D5DD;
      margin-right: 24px;
      border-radius: 4px;
      input{
        border-bottom: unset;
        padding-left:6px;
      }
    }
    .sent-email{
      height: 56px;
      width: 134px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color:$brandColor;
      border-radius: 4px;
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }

  @media screen and (max-width: 1050px){
    flex-direction: column;
    border-top: 1px solid #EBEBEB;
    padding: 28px 0 0 0;
    .subscribe-info{
      width: 100%;
      .subscribe-title{
        color: #231F20;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: -0.52px;
      }
      .subscribe-description{
        color: #696773;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
    .subscribe-input{
      flex-direction: column;
      height: unset;
      width: 100%;
      .custom-input-group{
        width: 100%;
        margin-right: unset;
      }
      .sent-email{
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}