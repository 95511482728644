@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.homeNews-wrapper{
  padding: 100px $mainPadding;

  .homeNews-header{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .homeNews-info{
      width: 502px;
      .homeNews-title{
        color: #231F20;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.72px;
      }
      .homeNews-description{
        color: #696773;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        margin-top: 20px;
      }
    }
    .invalid{
      border: 1px solid red;
    }
    .homeNews-input-wrapper{
      height: 56px;
      background-color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 4px;
      padding: 0 6px;
      margin-top: 48px;
      .custom-input-group{
        width:290px;
        margin-bottom: unset;
        border: 1px solid #D0D5DD;
        margin-right: 24px;
        border-radius: 4px;
        input{
          border-bottom: unset;
          padding-left: 8px;
        }
      }
      .sent-email{
        height: 56px;
        width: 134px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color:$brandColor;
        border-radius: 4px;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
  .homeNews-blocks{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 64px;
    gap: 32px;
  }

  @media screen and (max-width: 1050px){
    padding: 40px $mainPadding;
    .homeNews-header{
      flex-direction: column;
      .homeNews-info{
        width: 100%;
        .homeNews-title{
          font-size: 26px;
        }
        .homeNews-description{
          font-size: 18px;
        }
      }
      .homeNews-input-wrapper{
        flex-direction: column;
        height: unset;
        width: 100%;
        .custom-input-group{
          width: 100%;
          margin-right: unset;
        }
        .sent-email{
          width: 100%;
          margin-top: 20px;
        }
      }
    }
    .homeNews-blocks{
      flex-direction: column;
      margin-top: 36px;
      .news-block{
        width: 100%;
        gap: unset;
        margin-top: unset;
      }
    }
  }
}