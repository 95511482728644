@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";


.contacts-page-wrapper{
  -webkit-user-select:none!important;
  user-select: none!important;
  .contacts-block{
    padding: 0 0 80px $mainPadding ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .contacts-main-block{
      width: 44%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .contacts-main-text{
        width: 100%;
        margin-bottom: 24px;
        .main-title{
          color: #231F20;
          font-size: 36px;
          font-style: normal;
          font-weight: 600;
          line-height: 44px;
          letter-spacing: -0.36px;
        }
        .main-description{
          color: #696773;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
          margin-top: 20px;
        }
      }
      .contacts-inputs-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 52px;
        width: 100%;
        .fullName-inputs{
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          .custom-input-group{
            width: 100%;
            label{
              color: #D0D5DD;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
            }
            input{
              height: 44px;
              border-radius: 4px;
              border: 1px solid #D0D5DD;
              padding: 14px 12px;
              margin-top: 8px;
              color: #696773;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }
        .inputs{
          .custom-input-group{
            width: 100%;
            margin-bottom: 16px;
            label{
              color: #D0D5DD;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
            }
            input{
              height: 44px;
              border-radius: 4px;
              border: 1px solid #D0D5DD;
              padding: 14px 12px;
              margin-top: 8px;
              color: #696773;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }
            textarea{
              height: 120px;
              border-radius: 4px;
              border: 1px solid #D0D5DD;
              padding: 14px 12px;
              margin-top: 8px;
              color: #696773;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }
          }
          span{
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0;
            text-align: left;
            color: #344054;
            margin-bottom: 6px;
          }
          .phones-wrapper{
            border: 1px solid #D0D5DD;
            width: 100%;
            display: flex;
            align-items: center;
            border-radius: 8px;
            margin-bottom: 24px;
            margin-top: 6px;
            .custom-input-group{
              width: unset;
              margin-bottom: unset;
              .ant-select{
                width: 70px!important;
                margin-top: 6px;
                .ant-select-selector{
                  border: unset;
                  display: flex;
                  align-items: center;
                  .ant-select-selection-item{
                    margin-bottom: 2px!important;
                  }
                }
              }
              input{
                width: 100%;
                height: 44px;
                border: unset;
                padding: unset;
                margin-bottom: 2px;
              }
            }
          }
          .invalidPhone{
            border: 1px solid red;
          }
        }
      }
      .contact-services{
        width: 100%;
        display: flex;
        flex-direction: column;
        .contact-services-title{
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0;
          text-align: left;
          color: #344054;
          margin-bottom: 16px;
        }
        .services-block{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .ant-checkbox-group{
            width: 100%;
            .ant-row{
              width: 100%;
              .services-left-block, .services-right-block{
                width: 48%;
                display: flex;
                flex-direction: column;
                .ant-checkbox-wrapper{
                  margin-bottom: 10px;
                  .ant-checkbox{
                    .ant-checkbox-inner{
                      width: 20px;
                      height: 20px;
                      border: 1px solid #D0D5DD;
                    }
                  }
                  span{
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0;
                    text-align: left;
                    color: #344054;
                  }
                }
              }
            }
          }
        }
      }
      .sent-email{
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color:$brandColor;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        border-radius: 4px;
      }
    }
    .contact-map-block{
      width: 50%;
      height: 920px;
      .yandexMap{
        width: 100%;
        height: 100%;
        .ymaps-2-1-79-map{
          width: 100%!important;
          height: 100%!important;

        }
      }
    }
  }
  .information-block{
    width: 100%;
    padding: 60px $mainPadding;
    .information-block-header{
      .information-block-title{
        color: #231F20;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.396px;
      }
      .information-block-description{
        color: #696773;
        text-align: left;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        margin-top: 20px;
      }
    }
    .contact-info-wrapper{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;
      .contact-info{
        width:calc(96% / 3);
        height: 272px;
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        padding: 24px;
        .info-icon{
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .info-title{
          color: #231F20;
          font-family: NotoArmenian;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px;
          text-align: left;
          margin-top: 54px;
        }
        .wrapper{
          height: 60px;
          margin-top: 8px;
          display: flex;
          flex-direction: column;
          .information{
            color: #696773;
            font-family: NotoArmenian;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
          }
        }
      }
    }
  }
  .contact-page-img{
    position: relative;
    padding-top: 25.8%;
    img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .subscribe-wrapper{
    padding: 0 $mainPadding 100px $mainPadding;
  }

  @media screen and (max-width: 1100px){
    .contacts-block{
      flex-direction: column;
      padding: 60px $mainPadding;
      .contacts-main-block{
        width: 100%;
        .contacts-main-text{
          .main-title{
            font-size: 30px;
            line-height: 40px;
          }
          .main-description{
            font-size: 14px;
            line-height:20px;
          }
        }
        .contacts-inputs-wrapper{
          .fullName-inputs{
            flex-direction: column;
            .custom-input-group{
              width: 100%;
            }
          }
        }
      }
      .contact-map-block{
        display: none;
      }
    }
    .information-block{
      .information-block-header{
        .information-block-title{
          color: #231F20;
          font-family: NotoArmenian;
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: 38px;
          letter-spacing: -0.308px;
        }
        .information-block-description{
          color: #696773;
          font-family: NotoArmenian;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
        }
      }
      .contact-info-wrapper{
        flex-direction: column;
        .contact-info{
          width: 100%;
          margin-top: 32px;
          .wrapper{
            .information{
              font-size: 16px;
            }
          }
        }
      }
    }
    .subscribe-wrapper{
      border-top: unset;
      padding: 0 $mainPadding;
    }
  }
}
