@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.service-wrapper {
  .services-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    .services-header-info {
      width: 48%;
      padding: 0 0 0 $mainPadding;
      overflow: hidden;

      .services-header-title {
        color: #231F20;
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -0.48px;
      }

      .services-header-description {
        color: #696773;
        font-family: NotoArmenian;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        margin-top: 20px;
      }
    }

    .services-header-img {
      width: 48%;

      .img-wrapper {
        position: relative;
        padding-top: 92.1%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .services-blocks-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
    .services-blocks-title{
      color: #231F20;
      text-align: center;
      font-family: NotoArmenian;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: -0.6px;
    }
    .services-blocks-description{
      width: 80%;
      color: #696773;
      text-align: center;
      font-family: NotoArmenian;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin-top: 20px;
    }
    .services-blocks{
      margin-top: 120px;
      width: 100%;
      .blocks-wrapper{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom:110px;
        position: relative;
        .blocks-leftBg-wrapper{
          position: absolute;
          left: 0;
          transform: translate(0,46%);
          opacity: 0;
          z-index: -1;
        }
        .blocks-rightBg-wrapper{
          position: absolute;
          right: 0;
          transform: translate(0,58%);
        }
        &:nth-child(2n){
          .blocks-leftBg-wrapper{
            opacity: unset;
          }
          .blocks-rightBg-wrapper{
            opacity: 0;
          }
          flex-direction: row-reverse;
          .blocks-img-wrapper{
            padding: 0 0 0 $mainPadding;
          }
          .blocks-info-wrapper{
            padding:0 $mainPadding 0 0;
          }
        }
        .blocks-info-wrapper{
          width: 48%;
          padding: 0 0 0 $mainPadding;
          .blocks-info-title{
            color: #231F20;
            font-family: NotoArmenian;
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: 44px;
            letter-spacing: -0.72px;
          }
          .blocks-info-description{
            color: #696773;
            font-family: NotoArmenian;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            margin-top: 20px;
          }
          .blocks-info-more{
            color: #1A5AFF;
            font-family: NotoArmenian;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin-top: 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
            svg{
              width: 24px;
              height: 24px;
              margin-left: 6px;
              path{
                stroke:$brandColor;
              }
            }
          }
        }
        .blocks-img-wrapper{
          width: 48%;
          padding:0 $mainPadding 0 0;
          .blocks-img{
            position: relative;
            padding-top: 73.7%;
            img{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width:1050px){
    padding: 52px $mainPadding 0 ;
    .services-header{
      flex-direction: column;
      .services-header-info{
        width: 100%;
        .services-header-title{
          color: #231F20;
          font-family: NotoArmenian;
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 46px;
          letter-spacing: -0.36px;
        }
        .services-header-description{
          color: #696773;
          font-family: NotoArmenian;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          margin-top: 20px;
        }
      }
      .services-header-img{
        display: none;
      }
    }
    .services-blocks-wrapper{
      .services-blocks-title{
        color: #231F20;
        text-align: center;
        font-family: NotoArmenian;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: -0.52px;
      }
      .services-blocks-description{
        width: 100%;
        color: #696773;
        text-align: center;
        font-family: NotoArmenian;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
      .services-blocks{
        padding: 0 $mainPadding;
        .blocks-wrapper{
          flex-direction: column-reverse;
          margin-bottom: 44px;
          .blocks-rightBg-wrapper{
            display: none;
          }
          .blocks-leftBg-wrapper{
            display: none;
          }
          &:nth-child(2n){
            flex-direction: column-reverse;
            .blocks-img-wrapper{
              padding: unset;
            }
          }
          .blocks-img-wrapper{
            padding: unset;
            width: 100%;
          }
          .blocks-info-wrapper{
            width: 100%;
            padding: unset;
            margin-top: 12px;
            .blocks-info-title{
              color: #231F20;
              font-family: NotoArmenian;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: 28px;
              letter-spacing: -0.4px;
            }
            .blocks-info-description{
              color: #696773;
              font-family: NotoArmenian;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}