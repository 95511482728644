@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";


.favoriteProduct-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  overflow: auto;
  //transition: all 0.35s cubic-bezier(.39, .575, .565, 1);
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.45);
  //display: flex;
  //justify-content: flex-end;

  &.expand {
    visibility: visible;
    opacity: 1;
  }

  .order-block-wrapper {
    width: 698px;
    position: absolute;
    right: 0;
    background-color: #293445;
    //height: 100%;
    .favoriteProduct-header {
      background: rgba(26, 90, 255, 0.20);
      width: 100%;
      height: 162px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 44px;

      .favoriteProduct-info {
        width: 80%;

        .favoriteProduct-header-title {
          color: #FFF;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
          letter-spacing: -0.48px;
        }

        .favoriteProduct-header-description {
          color: #D0D5DD;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          margin-top: 12px;
        }
      }

      .favoriteProduct-close {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
    }

    .favoriteProducts-block {
      background-color: #344054;
      padding: 96px 56px 56px 56px;

      .favoriteProduct {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;

        .favoriteProduct-title {
          width: 80%;
          color: #AAB5C9;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .favoriteProduct-trash {
          width: 40px;
          height: 40px;
          cursor: pointer;
        }

        .favoriteProduct-hover-trash {
          width: 40px;
          height: 40px;
          cursor: pointer;
          display: none;
        }

        &:hover {
          .favoriteProduct-title {
            color: white;
          }

          .favoriteProduct-trash {
            display: none;
          }

          .favoriteProduct-hover-trash {
            display: flex;
          }
        }
      }
    }

    .favoriteProduct-inputs-wrapper {
      padding: 100px 44px;

      .favoriteProducts-input-title {
        color: #FFF;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.72px;
      }

      .favoriteProducts-input-description {
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        margin-top: 20px;
      }

      .favoriteProducts-inputs {
        margin-top: 40px;

        .custom-input-group {
          width: 100%;
          margin-bottom: 16px;

          input {
            border-radius: 4px;
            border: 1px solid #D0D5DD;
            padding-left: 16px;
            color: #D0D5DD;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-top: 8px;
          }

          label {
            color: #D0D5DD;
            font-family: NotoArmenian;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }
        }

        .sent-email {
          width: 100%;
          cursor: pointer;
          height: 56px;
          margin-top: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          background: #1A5AFF;
          color: #FFF;
          text-align: center;
          font-family: NotoArmenian;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .order-block-wrapper{
      width: 100%;
      .favoriteProduct-header {
        flex-direction: column-reverse;
        height: unset;
        padding: 16px 16px 46px 16px;

        .favoriteProduct-info {
          width: 100%;

          .favoriteProduct-header-title {
            color: #FFF;
            font-family: NotoArmenian;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: -0.32px;
          }

          .favoriteProduct-header-description {
            color: #D0D5DD;
            font-family: NotoArmenian;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            margin-top: 8px;
          }
        }

        .favoriteProduct-close {
          display: flex;
          align-self: flex-end;
          width: 32px;
          height: 32px;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
      .favoriteProducts-block {
        padding: 68px 20px;

        .favoriteProduct {
          .favoriteProduct-title {
            color: #AAB5C9;
            font-family: NotoArmenian;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }
        }
      }
      .favoriteProduct-inputs-wrapper {
        padding: 54px 16px;

        .favoriteProducts-input-title {
          color: #FFF;
          font-family: NotoArmenian;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: -0.4px;
        }

        .favoriteProducts-input-description {
          color: #EBEBEB;
          font-family: NotoArmenian;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }
}