@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.about-wrapper {
  padding: 0 $mainPadding;

  .about-header {
    padding: 140px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .about-title {
      color: #231F20;
      text-align: center;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -0.48px;
    }

    .about-description {
      width: 80%;
      color: #696773;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin-top: 20px;
    }
  }

  .about-blocks-wrapper {
    padding: 80px 0;

    .about-blocks-card {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 120px;

      &:nth-child(2n) {
        flex-direction: row-reverse;
      }

      .about-blocks-description {
        width: 48%;
        color: #696773;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
      }

      .about-blocks-img {
        width: 48%;

        .about-img {
          position: relative;
          padding-top: 52.4%;

          img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
          }
        }
      }
    }
  }

  .about-partners-block{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 140px 0;
    .about-partners-header{
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .about-partners-title{
        color: #231F20;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.72px;
      }
      .about-partners-description{
      color: #696773;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin-top: 20px;
    }
    }
    .about-partners-wrapper{
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      gap: 32px;
      margin-top: 52px;
      .about-partners-card{
        width:calc(100% / 3 - 62px);
        border: 1px solid #D0D5DD;
        padding: 0 24px;
        .about-partners{
          padding-top: 80.3%;
          position: relative;
          img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .about-feedback{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 100px 0;
    .feedback-info{
      width: 48%;
      .feedback-title{
        color: #231F20;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.72px;
      }
      .feedback-description{
        color: #696773;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        margin-top: 20px;
      }
    }
    .feedback-input{
      width: 384px;
      .custom-input-group{
        width: 100%;
        margin-bottom: 16px;
        input{
          border-radius: 4px;
          border: 1px solid #D0D5DD;
          padding-left: 16px;
          color: #696773;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin-top: 8px;
        }
      }
      .sent-email{
        height: 56px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color:$brandColor;
        border-radius: 4px;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }

  @media screen and (max-width: 1050px){
    .about-header{
      padding: 52px 0;
      .about-title{
        color: #231F20;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 46px;
        letter-spacing: -0.36px;
        text-align: left;
      }
      .about-description{
        width: 100%;
        text-align: left;
        color: #696773;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
      }
    }
    .about-blocks-wrapper{
      .about-blocks-card{
        flex-direction: column-reverse;
        margin-bottom: 52px;

        &:nth-child(2n){
          flex-direction: column-reverse;
        }

        .about-blocks-description{
          width: 100%;
          color: #696773;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          margin-top: 24px;
        }
        .about-blocks-img{
          width: 100%;
        }
      }
    }
    .about-partners-block{
      padding: 40px 0 0 0;
      border-top: 1px solid #EBEBEB;
      .about-partners-header{
        width: 100%;
        .about-partners-title{
          color: #231F20;
          font-family: NotoArmenian;
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: 38px;
          letter-spacing: -0.52px;
        }
        .about-partners-description{
          color: #696773;
          text-align: center;
          font-family: NotoArmenian;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }

      }
      .about-partners-wrapper{
        flex-wrap: unset;
        gap: unset;
        flex-direction: column;
        .about-partners-card{
          width: 100%;
          margin-bottom: 20px;
        }
      }
      .about-feedback{
        flex-direction: column;
        padding: 100px 0 0 0;
        .feedback-info{
          width: 100%;
          .feedback-title{
            color: #231F20;
            font-family: NotoArmenian;
            font-size: 26px;
            font-style: normal;
            font-weight: 600;
            line-height: 38px;
            letter-spacing: -0.52px;
          }
          .feedback-description{
            color: #696773;
            font-family: NotoArmenian;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }
        }
        .feedback-input{
          width: 100%;
          margin-top: 40px;
        }
      }
    }
  }
}