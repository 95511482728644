@import "../../../../assets/styles/mainVariables";
@import "../../../../assets/styles/fonts";


.news-block{
  width: calc(94% / 3);
  margin-top: 40px;
  .news-block-img{
    position: relative;
    aspect-ratio: 350 / 220;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 14px;
    }
  }
  .news-block-info{
    width: 100%;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    .news-date{
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      color:$brandColor;
    }
    .news-title-wrapper{
      width: 100%;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      margin-top: 12px;
      .news-block-title{
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0;
        text-align: left;
        width: 96%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .news-block-link{
        cursor: pointer;
      }
    }
    .news-block-description{
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      color: #667085;
      margin-top: 12px;
    }
  }
  @media screen and (max-width: 1800px){
    width: calc(90% / 3);
  }
}
//:hover{
//  .news-title-wrapper{
//    .news-block-title{
//      color:$brandColor;
//    }
//    .news-block-link{
//      svg{
//        path{
//          stroke:$brandColor;
//        }
//      }
//    }
//  }
//}

@media screen and (max-width: 1100px){
  .news-block{
    width: 100%;
    .news-block-img{
      position: relative;
      aspect-ratio: 343 / 200;
    }
    .news-block-info{
      .news-date{
        font-size: 14px;
      }
      .news-title-wrapper{
        .news-block-title{
          font-size: 20px;
        }
      }
    }
  }
}